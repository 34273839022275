<template>
  <div>
    <div class="d-flex align-center">
      <!-- 'Online' Chip -->
      <v-tooltip bottom open-delay="250" :color="determineColor">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-chip v-if="numOfOnlineResources" @click="showResourcesDialog" class="ml-1" small link text-color="white" :color="determineColor">
              <b v-if="totalNumOfIssues">{{ totalNumOfIssues }}&nbsp;</b>
              <v-icon size="medium">{{ totalNumOfIssues ? `mdi-alert-circle` : `mdi-check-circle`}}</v-icon>
            </v-chip>
          </div>
        </template>
        <!-- tooltip text box -->
        <div class="text-center"> 
          <b v-if="!totalNumOfIssues">{{ numOfOnlineResources }} computers online</b>
          <div v-if="totalNumOfIssues">
            <div class="text-decoration-underline font-weight-bold">
              {{ numOfOnlineResources }} computers online
            </div>
            <div class="d-flex align-center" v-if="numOfWarnings"> 
              <v-icon class="mr-1" size="large" color="white">mdi-alert-circle</v-icon>
              {{numOfWarnings}} warnings
            </div>
            <div class="d-flex align-center" v-if="numOfErrors">
              <v-icon class="mr-1" size="large" color="white">mdi-alert-circle</v-icon>
              {{numOfErrors}} errors 
            </div>
          </div>
        </div>
      </v-tooltip>
      <!-- 'Unplugged' Chip -->
      <v-tooltip bottom open-delay="250" color="danger">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-chip v-if="numOfOfflineResources" @click="showResourcesDialog" class="ml-1" small link outlined color="danger">
              <v-icon size="medium">mdi-power-plug-off</v-icon>
            </v-chip>
          </div>
        </template>
        <!-- tooltip text box -->
        <div>
          <b class="text-decoration-underline">{{ numOfOfflineResources }} computers offline:</b>
          <div v-for="(resource, i) in monitoring.filter(resource => !resource.has_recent_event)" :key="i">
            {{ resource.name }} - Last seen {{ $dayjs(resource.latest_event.sync_time).fromNow() }}
          </div>
        </div>
      </v-tooltip>
      <!-- Payment Chip -->
      <v-tooltip v-if="isFailedPayments" bottom open-delay="250" color="warning">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-chip class="ml-2" small link color="warning" v-bind="$attrs" @click="showRecentPaymentsDialog = true">
            <v-icon size="medium">mdi-credit-card-multiple</v-icon>
            </v-chip>
          </div>
        </template>
        <div>
          <b>Problems with recent payments</b>
        </div>
      </v-tooltip>

      <!-- 'Printer' Chip -->
      <v-tooltip v-if="printerMetric" bottom open-delay="250" :color="determinePrinterIconColor">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-chip @click="showResourcesDialog" class="ml-2" small link outlined :color="determinePrinterIconColor" style="font-size: .65rem;">
              <v-icon size="medium">mdi-printer</v-icon>&nbsp;{{ribbonLevel}}%
            </v-chip>
          </div>
        </template>
        <div>{{ printerMetric.message }}</div>
      </v-tooltip>
    </div>

    <!-- Monitor Resource Card Dialog -->
    <v-dialog v-model="resourceDialog" :maxWidth="monitoring?.length > 1 ? 1000 : 600">
      <v-card>
        <v-card-title class="headline">
          {{ profileName || profileId }}
          <v-spacer></v-spacer>
          <v-icon @click="resourceDialog = false" class="cursor-pointer">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col 
              v-for="(resource, index) in monitoring" :key="index" 
              cols="12" :sm="monitoring.length > 1 ? `6`: `12`">
              <monitored-resource-card :resource="resource" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Recent Payments Dialog -->
    <v-dialog v-model="showRecentPaymentsDialog" :maxWidth="1000">
      <v-card>
        <v-card-title class="headline">
          {{ profileName || profileId }}
          <v-spacer></v-spacer>
          <v-icon @click="showRecentPaymentsDialog = false" class="cursor-pointer">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <div>
            <PaymentsTable
            :profile-id="profileId"
            :reverse="true"
            :page-size="5"/>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MonitoredResourceCard from './MonitoredResourceCard.vue';
import PaymentsTable from "../components/PaymentsTable.vue";
import {convertLegacyMetric, isResourceOnline} from '../utils'

export default {
  name: 'MonitoringChips',
  components: {MonitoredResourceCard, PaymentsTable},
  props: {
    profileId: {
      type: String,
      required: true
    },
    profileName: {
      type: String
    },
    monitoredResources: {
      type: Array,
    },
    payments: {
      type: Array,
    }
  },
  data() {
    return {
      resourceDialog: false,
      showRecentPaymentsDialog: false
    };
  },

  computed: {
    monitoring() {
      return this.monitoredResources || [];
    },

    printerMetric() {
      let printerMetric = null;
      if (this.monitoring) {
        this.monitoring.forEach(resource => {
          let metrics = resource.latest_event.metrics;
          for (let i = 0; i < metrics.length; i++) {
            if (metrics[i].id === "printer.supply-level.ribbonlevel") {
              printerMetric = metrics[i];
            }
          }
        });
      }
      return printerMetric;
    },

    ribbonLevel() {
      return this.printerMetric ? Math.round(this.printerMetric.value * 100) : null;
    },

    numOfWarnings() {
      return this.numOfIssues("warning");
    },

    numOfErrors() {
      return this.numOfIssues("error");
    },

    totalNumOfIssues() {
      return this.numOfWarnings + this.numOfErrors;
    },

    numOfOnlineResources() {
      return this.monitoring.filter(isResourceOnline).length;
    },

    numOfOfflineResources() {
      return this.monitoring.length - this.numOfOnlineResources;
    },

    determineColor() {
      return this.numOfErrors ? 'error' : this.numOfWarnings ? 'warning' : 'success';
    },

    determinePrinterIconColor() {
      if (this.printerMetric.status === 'error' || !this.printerMetric.status) {
        return 'error';
      } else if (this.printerMetric.status === 'warning') {
        return 'warning';
      } else {
        return 'success';
      }
    },

    isFailedPayments() {
      if (!this.payments || this.payments.length === 0) {
        return false;
      }

      const failedPayments = this.payments.filter(payment => 
        (payment.status === "failed" || (payment.status === "declined" && payment.message?.startsWith("Service Error") ) || payment.status === "cancelled")
      );

      if (failedPayments.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    
    numOfIssues(issueType) {
      let issues = 0;
      if (this.monitoring) {
        this.monitoring.forEach(resource => {
          issues += this.checkForIssuesOnResource(resource, issueType);
        });
      }
      return issues;
    },

    checkForIssuesOnResource(resource, issueType) {
      if (!isResourceOnline(resource)) return 0;
      let issues = 0;
      let metrics = resource.latest_event.metrics;
      for (let i = 0; i < metrics.length; i++) {
        if (convertLegacyMetric(metrics[i]).status === issueType) {
          if (metrics[i].id != "printer.supply-level.ribbonlevel") issues++;
        }
      }
      return issues;
    },

    showResourcesDialog() {
      this.resourceDialog = true;
    },
  }
};
</script>
