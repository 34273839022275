<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h4">Dashboard</h2>
      <v-btn text :loading="reportNotLoaded" @click="copyReportToClipboard" :color="copySuccess ? 'success' : 'default'">
        <template v-if="!copySuccess">
          <v-icon left>mdi-content-copy</v-icon>
          Copy Report
        </template>
        <template v-else>
          <v-icon left>mdi-check</v-icon>
          Copied!
        </template>
      </v-btn>
    </div>
    
    <!-- Controls -->
    <div class="d-flex justify-space-between align-center">
      <v-text-field
        v-model="filter"
        prepend-inner-icon="mdi-magnify"
        label="Filter Profiles"
        single-line
        hide-details
        dense
        outlined
        class="shrink"
      ></v-text-field>

      <v-btn-toggle v-model="isListView" mandatory group dense>
        <v-btn class="no-text" :value="false">
          <v-icon>mdi-view-grid</v-icon>
        </v-btn>
        <v-btn class="no-text" :value="true">
          <v-icon>mdi-view-list</v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
    <v-divider class="mb-6 mt-2" />

    <!-- Grid View -->
    <template v-if="!isListView">
      <v-row v-show="filteredInstalls.length">
        <v-col v-for="(install, index) in filteredInstalls" :key="install.id" cols="6" sm="4" md="3">
          <v-card outlined height="100%" class="d-flex flex-column">
            
            <!-- Card Thumbnail -->
            <div class="thumbnail" @click="showPreview(index)">
              <deliverable-thumbnail :deliverable="install.latest_deliverable" />
              <div class="thumbnail-shading transparent-hover"/>
              <dashboard-dropdown-menu
                :install="install" 
                class="thumbnail-icon transparent-hover ma-1" 
                @open-knowledge-base="openKnowledgeBaseDialog(install)"
              />
            </div>

            <!-- Card Body -->
            <v-card-text class="d-flex flex-column justify-space-between" style="flex-grow: 1;">
              <div>
                <b style="color: black">{{ install.name || install.id }}</b><br />
                <b>Last Capture: </b>{{ $dayjs(install.latest_deliverable.sync_time).fromNow() }}<br />
              </div>
              <monitoring-chips class="mt-3" 
                :profileId="install.id" 
                :profileName="install.name" 
                :monitoredResources="install.monitored_resources" 
                :payments="install.latest_payments" 
              />
            </v-card-text>
            
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!installs.length && reportNotLoaded">
        <v-col v-for="index in 20" :key="index" cols="6" sm="4" md="3">
          <v-skeleton-loader type="card, list-item@2"></v-skeleton-loader>
        </v-col>
      </v-row>

      <p v-else-if="!filteredInstalls.length" class="text-center">No profiles found</p>
    </template>

    <!-- List View -->
    <template v-else>
      <v-card>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th>Profile</th>
                <th>Status</th>
                <th>Last Capture</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="install in filteredInstalls" :key="install.id">
                <td>{{ install.name || install.id }}</td>
                <td>
                  <monitoring-chips 
                    :profileId="install.id" 
                    :profileName="install.name" 
                    :monitoredResources="install.monitored_resources" 
                    :payments="install.latest_payments"
                  />
                </td>
                <td class="d-flex align-center justify-space-between">
                  <span>{{ $dayjs(install.latest_deliverable.sync_time).fromNow() }}</span>
                  <dashboard-dropdown-menu
                    :install="install" 
                    class="transparent-hover" :listView="true"
                    @open-knowledge-base="openKnowledgeBaseDialog(install)"
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-skeleton-loader v-if="!filteredInstalls.length && reportNotLoaded" type="table-tbody" :types="{'table-row': 'table-cell@3'}"></v-skeleton-loader>
          
          <p v-else-if="!filteredInstalls.length" class="text-center mt-6">No profiles found</p>
        </v-card-text>
      </v-card>
    </template>
    
    <!-- Dialogs -->
    <knowledge-base-dialog ref="knowledgeBaseDialog"/>
    <deliverable-preview-dialog
      ref="preview"
      nav
      :previousDisabled="previewItemIndex === 0"
      :nextDisabled="previewItemIndex === filteredInstalls.length - 1"
      @previous="showPreview(previewItemIndex - 1)"
      @next="showPreview(previewItemIndex + 1)"
    />

  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import DeliverableThumbnail from '../components/DeliverableThumbnail.vue';
import DeliverablePreviewDialog from '../components/DeliverablePreviewDialog.vue';
import MonitoringChips from '../components/MonitoringChips.vue';
import DashboardDropdownMenu from '../components/DashboardDropdownMenu.vue';
import KnowledgeBaseDialog from '../components/KnowledgeBaseDialog.vue';

export default {
  name: 'Dashboard',
  components: {
    DeliverableThumbnail,
    DeliverablePreviewDialog,
    DashboardDropdownMenu,
    MonitoringChips,
    KnowledgeBaseDialog
  },

  data() {
    const formattedDate = `Perm Installs Report ${dayjs().format('dddd, MMMM D, YYYY (h:mmA)')} \n----------------------------------------\n`;
    return {
      installs: [],
      report: formattedDate,
      reportNotLoaded: true,
      copySuccess: false,
      copySuccessTimer: null,
      previewItemIndex: null,
      isListView: false,
      filter: '',
      knowledgeBaseDialog: false
    };
  },

  async created() {
    await this.fetchInstalls()
    if (this.installs) this.assembleReport();
  },

  computed: {
    sortedInstalls() {
      return this.installs.slice().sort((a, b) => {
        const nameA = (a.name || a.id).toLowerCase();
        const nameB = (b.name || b.id).toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
    filteredInstalls() {
      if (!this.filter) {
        return this.sortedInstalls;
      }

      let lowerCaseFilter = this.filter.toLowerCase();
      let installs = this.sortedInstalls.filter(install =>
        install.id.toLowerCase().includes(lowerCaseFilter) ||
        (install.name && install.name.toLowerCase().includes(lowerCaseFilter))
      );
      return installs;
    },
  },

  methods: {
    async fetchInstalls() {
      try {
        let response = await this.$api.get('dashboard/installs');
        let installs = response.data.installs.filter(install => 
          install.latest_deliverable && install.latest_deliverable.thumbnail
        );
        this.installs = installs;
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    },

    assembleReport() {
      let installs = this.sortedInstalls;
      // gathering data for the report
      for (let i = 0; i < installs.length; i++) {
        let deliverable = installs[i].latest_deliverable;
        if (!deliverable) continue; // Skip if no deliverable

        let daysAgo = this.$dayjs().diff(this.$dayjs(deliverable.create_time), 'day'); // how many days ago last capture was.

        this.report += `${i + 1}. ${installs[i].name || installs[i].id}`;
        this.report += ` ~ Last Capture ${this.$dayjs(deliverable.create_time).fromNow()}${daysAgo > 3 ? ' ⚠️':''}\n`; 
        // add emoji if capture was more than 3 days ago.
      }
      this.reportNotLoaded = false;
    },

    async copyReportToClipboard() {
      try {
        await navigator.clipboard.writeText(this.report);
        this.copySuccess = true;
        clearTimeout(this.copySuccessTimer);
        this.copySuccessTimer = setTimeout(() => { this.copySuccess = false }, 2000);
      } catch (err) {
        this.copySuccess = false;
        console.error('Failed to copy text: ', err);
      }
    },

    showPreview(index) {
      this.previewItemIndex = index;
      this.$refs.preview.open(this.filteredInstalls[index].latest_deliverable);
    },

    openKnowledgeBaseDialog(install) {
      this.knowledgeBaseDialog = true;
      this.$refs.knowledgeBaseDialog.open(install);
    }
  }
};
</script>

<style scoped>
.thumbnail {
  position: relative;
  width: 100%;
  height: fit-content;
  aspect-ratio: 4 / 5;
  object-fit: cover;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
}

.thumbnail-shading {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12%;
  opacity: 0;
  background: linear-gradient(rgba(75, 74, 74, 0), rgba(162, 162, 162, 0.43));
  transition: opacity 0.3s;
}

.thumbnail-icon {
  position: absolute;
  color: #000;
  top: 0;
  right:0
}

.transparent-hover {
  opacity: 0;
  transition: opacity 0.3s;
}

.thumbnail:hover .transparent-hover, tr:hover .transparent-hover {
  opacity: 1;
}
</style>