<template>
  <div>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :dark="listView ? false : true"
          icon
          v-bind="attrs"
          v-on="on"
          :style="{backgroundColor: listView ? '#0000' :`#00000045`}"
        >
          <v-icon :style="{ scale: listView ? 1 : 1.1 }">mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <div v-for="(item, i) in menuItems" :key="i">
          <v-list-item dense
            link color="primary"
            @click="handleItemClick(item.action)"
          >
            <v-icon>mdi-{{ item.icon }}</v-icon>
            <v-list-item-title class="ma-2">{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-divider v-if="item.divider" class="my-1"></v-divider>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DashboardDropdownMenu",
  props: {
    listView: {
      default: false,
      type: Boolean
    },
    install: {
      required: true,
      type: Object
    }
  },

  computed: {
    archbeeUrl() {
      let link = this.install.external_links.find(link => link.type == "archbee");
      return link?.url;
    },

    menuItems() {
      let items = [];

      if (this.archbeeUrl) {
        items.push({ 
          title: 'Knowledge Base',
          icon: 'head-question',
          action: 'openKnowledgeBase',
          divider: true
        });
      }
      
      items.push({ 
        title: 'Content',
        icon: 'image-multiple',
        action: 'goToContent'
      });

      items.push({ 
        title: 'Stats',
        icon: 'chart-timeline-variant-shimmer',
        action: 'goToStats',
        divider: true
      });

      items.push({ 
        title: 'Guest Data',
        icon: 'account-box-multiple',
        action: 'goToGuestData'
      })

      items.push({ 
        title: 'Deliveries',
        icon: 'email-fast',
        action: 'goToDeliveries'
      });

      if (this.install.latest_payments?.length) {
        items.push({ 
          title: 'Payments',
          icon: 'credit-card-multiple',
          action: 'goToPayments'
        });
      }

      return items;
    }
  },
  
  methods: {
    handleItemClick(action) {
      switch (action) {
        case 'openKnowledgeBase':
          this.$emit('open-knowledge-base');
          break;
        case 'goToContent':
          this.navigateTo('content');
          break;
        case 'goToStats':
          this.navigateTo('stats');
          break;
        case 'goToGuestData':
          this.navigateTo('data');
          break;
        case 'goToDeliveries':
          this.navigateTo('deliveries');
          break;
        case 'goToPayments':
          this.navigateTo('payment');
          break;
        default:
          console.warn('No action defined:', action);
      }
    },

    navigateTo(routeName) {
      this.$root.profileId = this.install.id;
      this.$router.push({ name: routeName });
    },
  },


}
</script>