import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from './views/Login';
import Content from './views/Content';
import ProfileSchedules from './views/ProfileSchedules';
import EditProfile from './views/EditProfile';
import ContentDetail from './views/ContentDetail';
import Data from './views/Data';
import DataDetail from './views/DataDetail';
import Payment from './views/Payments.vue';
import Dashboard from './views/Dashboard'
import Templates from './views/Templates';
import TemplateDetail from './views/TemplateDetail';
import TemplateForm from './views/TemplateForm';
import Deliveries from './views/Deliveries';
import DeliveryDetail from './views/DeliveryDetail';
import Stats from './views/Stats';
import Profiles from './views/Profiles'
import ProfileDetail from './views/ProfileDetail'
import ProfileForm from './views/ProfileForm'
import Monitoring from './views/Monitoring';
import Users from './views/Users';
import UserDetail from './views/UserDetail';
import UserForm from './views/UserForm';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    // Authenticaion
    { 
      path: '/login',
      name: 'login',
      meta: { title: 'Login' },
      component: Login 
    },
        
    // Profiles Admin
    { 
      path: '/profile-admin',
      name: "profile-admin",
      meta: { title: 'Profiles' },
      component: Profiles,
      props: true
    },
    { 
      path: "/profile-admin/:profileId", 
      name: "profile-detail", 
      meta: { title: 'Profile Detail' },
      component: ProfileDetail, 
      props: true
    },
    { 
      path: "/profile-admin/:profileId/edit", 
      name: "profile-edit", 
      meta: { title: 'Edit Profile' },
      component: ProfileForm, 
      props: true
    },
    
    // Customisation and Scheduling
    { 
      path: '/profiles',
      name: 'profiles',
      meta: { title: 'Customisation' },
      component: ProfileSchedules 
    },
    {
      path: '/profiles/:profileName',
      name: 'profiles',
      meta: { title: 'Customisation' },
      component: ProfileSchedules,
    },
    {
      path: '/profile/:profileName/default',
      name: 'profile',
      meta: { title: 'Create Schedule' },
      component: EditProfile,
    },
    {
      path: '/profile/:profileName/schedule/:index',
      name: 'profile',
      meta: { title: 'Edit Schedule' },
      component: EditProfile,
    },
    {
      path: '/profile/:profileName/new-schedule',
      name: 'profile',
      meta: { title: 'New Schedule' },
      component: EditProfile,
    },

    // Dashboard
    { 
      path: '/dashboard',
      name: "dashboard",
      meta: { title: 'Dashboard' },
      component: Dashboard 
    },
    
    // Content
    { 
      path: '/content',
      name: 'content',
      meta: { title: 'Content' },
      component: Content 
    },
    {
      path: '/content/:id',
      name: 'content-detail',
      meta: { title: 'Content Detail' },
      component: ContentDetail,
      props: true,
    },

    // Guest Data
    { 
      path: '/data', 
      name: 'data', 
      meta: { title: 'Guest Data' },
      component: Data 
    },
    {
      path: '/data/:id',
      name: 'data-detail',
      meta: { title: 'Guest Data Detail' },
      component: DataDetail,
      props: true,
    },

    // Payments
    { 
      path: '/payments',
      name: 'payment',
      meta: { title: 'Payments' },
      component: Payment 
    },
      
    // Email Templates
    { 
      path: '/templates',
      name: 'templates',
      meta: { title: 'Templates' },
      component: Templates 
    },
    {
      path: '/templates/:profileId/create',
      name: 'template-create',
      meta: { title: 'Create Template' },
      component: TemplateForm,
      props: true,
    },
    {
      path: '/templates/:profileId/:id',
      name: 'template-detail',
      meta: { title: 'Template Detail' },
      component: TemplateDetail,
      props: true,
    },
    {
      path: '/templates/:profileId/:id/edit',
      name: 'template-edit',
      meta: { title: 'Edit Template' },
      component: TemplateForm,
      props: true,
    },

    // Email Deliveries
    { 
      path: '/deliveries',
      name: 'deliveries',
      meta: { title: 'Deliveries' },
      component: Deliveries 
    },
    {
      path: '/deliveries/:id',
      name: 'delivery-detail',
      meta: { title: 'Delivery Detail' },
      component: DeliveryDetail,
      props: true,
    },

    // Stats
    { 
      path: '/stats',
      name: 'stats',
      meta: { title: 'Stats' },
      component: Stats 
    },
    
    // Monitoring
    { 
      path: '/monitoring',
      name: 'monitoring',
      meta: { title: 'Monitoring' },
      component: Monitoring 
    },
    
    // Users
    { 
      path: '/users',
      name: 'users',
      meta: { title: 'Users' },
      component: Users 
    },
    {
      path: '/users/create',
      name: 'user-create',
      meta: { title: 'Create User' },
      component: UserForm,
      props: true,
    },
    {
      path: '/users/:username',
      name: 'user-detail',
      meta: { title: 'User Detail' },
      component: UserDetail,
      props: true,
    },
    {
      path: '/users/:username/edit',
      name: 'user-edit',
      meta: { title: 'Edit User' },
      component: UserForm,
      props: true,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title + ' - Airtime' : 'Airtime';
  next();
});

export default router;