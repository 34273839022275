<template>
  <div>
    <v-dialog v-if="showDialog && install" v-model="showDialog" :maxWidth="1200">
      <v-card class="d-flex flex-column" style="height: 90vh">
        <v-card-title class="headline">
          <span>{{ install.name || install.id }}</span>
          <v-spacer></v-spacer>
          <v-btn class="mx-2" :href="archbeeLink" text target="_blank" icon title="Open in new tab">
            <v-icon class="cursor-pointer">mdi-open-in-new</v-icon>
          </v-btn>
          <v-icon @click="showDialog = false" class="cursor-pointer">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="flex-grow-1">
          <iframe :src="archbeeLink"
            @message="processMessage"
            frameborder="0"
            style="width: 100%;height: 100%;"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'KnowledgeBaseDialog',
  data() {
    return {
      showDialog: false,
      install: null,
      archbeeLink: null
    }
  },

  created() {
    window.addEventListener("message", this.processMessage);
  },

  destroyed() {
    window.removeEventListener("message", this.processMessage);
  },

  methods: {
    open(install) {
      this.showDialog = true;
      this.install = install;
      let link = this.install.external_links.find(link => link.type == "archbee")
      if (!link) {
        this.showDialog = false;
        return;
      }
      this.archbeeLink = link?.url;
    },

    processMessage(message) {
      // Because we can run custom code on the archbee site, we can inject the password automatically if requested
      if (message.origin === "https://docs.itstheflashpack.com" && message.data === "request-archbee-secret") {
        if (this.$root.token.archbee_secret) {
          message.source.postMessage("archbee-secret:" + this.$root.token.archbee_secret, message.origin);
        }
      }
    }
  }

};
</script>
