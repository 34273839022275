<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h4">Users</h2>
      <v-btn v-if="canCreate" text :to="{name: 'user-create'}">
        <v-icon left>mdi-plus</v-icon>
        Create User
      </v-btn>
    </div>

    <!-- Alerts -->
    <status-alert />

    <!-- Card -->
    <v-card>
      <v-card-text>
        <!-- Table -->
        <v-simple-table v-if="items.length">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email</th>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>
                <router-link :to="{name: 'user-detail', params: {username: item.username}}">
                  {{item.username}}
                </router-link>
              </td>
              <td>{{item.email}}</td>
              <td>
                <template v-if="item.name">{{item.name}}</template>
                <template v-else>-</template>
              </td>
              <td>
                <status-chip :status="item.status" small />
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <async-state :promise="promise" :has-data="items.length > 0">
          <div v-if="nextPageToken" class="text-center my-4">
            <v-btn text @click="loadMore">
              Load more
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </async-state>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import axios from 'axios'
import AsyncState from '../components/AsyncState.vue'
import StatusChip from '../components/StatusChip.vue'
import StatusAlert from '../components/StatusAlert.vue'


export default {
  name: "Users",
  components: {AsyncState, StatusChip, StatusAlert},


  data() {
    return {
      items: [],
      promise: null,
      nextPageToken: null,
      cancelToken: null
    };
  },
  computed: {
    canCreate() {
      return (
        this.$root.permissions.canPerform("create_user", '*'));
    },
  },

  created() {
    this.loadInit();
  },

  methods: {
    loadInit() {
      this.items = [];
      this.nextPageToken = null;
      this.loadMore();
    },

    loadMore() {
      this.promise = (async () => {
        if (this.cancelToken)
          this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let response = await this.$api.get("/users", {
          params: {
            page_size: 50,
            page_token: this.nextPageToken
          },
          cancelToken: this.cancelToken.token
        });
          
        this.items = this.items.concat(response.data.items);
        this.nextPageToken = response.data.next_page_token;
      })();
    }
  }
};
</script>
