<template>
  <v-container class="px-lg-12">
    <!-- Header -->
    <div class="d-flex justify-space-between align-center mb-6">
      <h2 class="text-h4">Stats</h2>
    </div>

    <!-- Chart -->
    <v-card>
      <v-card-header class="d-flex justify-space-between align-center">
        <profile-picker v-model="profileId" />
        <v-btn-toggle v-model="binSize" group dense mandatory>
          <v-btn value="day" class="text-none">Day</v-btn>
          <v-btn value="month" class="text-none">Month</v-btn>
        </v-btn-toggle>
        <period-picker v-model="period" @computed="computedPeriod = $event" />
      </v-card-header>
      <v-card-text>
        <async-state v-if="profileId && computedPeriod" :promise="promise">
          <v-chart v-if="hasNonZeroStats" class="chart" :option="chartOption" autoresize />
          <p v-else class="grey--text text-center my-4">
            Nothing to display. Try selecting a wider date range.
          </p>
        </async-state>
        <p v-else-if="!profileId" class="grey--text text-center my-4">
          Select a profile to view stats
        </p>
      </v-card-text>
    </v-card>

    <!-- Figures -->
    <v-card v-if="profileId && computedPeriod && hasNonZeroStats" class="mt-6">
      <v-card-header class="d-flex align-center">
        <v-card-title>Figures</v-card-title>
        <v-spacer/>
        <v-btn text @click="downloadFiguresCSV()">
          Download CSV
          <v-icon right>mdi-download</v-icon>
        </v-btn>
      </v-card-header>
      <v-card-text>
          <async-state :promise="promise">
            <v-simple-table class="mb-6">
              <thead>
                <tr>
                  <th>Date</th>
                  <th v-if="totals.deliverables.total > 0">Content</th>
                  <th v-if="totals.submissions.total > 0">Guest Data</th>
                  <th v-if="totals.deliveries.total > 0">Email Deliveries</th>
                  <th v-if="totals.payments.complete > 0">Completed Payments</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.time">
                  <td>{{formatDate(item.time)}}</td>
                  <td v-if="totals.deliverables.total > 0">{{item.stats[0].metrics.deliverables.total}}</td>
                  <td v-if="totals.submissions.total > 0">{{item.stats[0].metrics.submissions.total}}</td>
                  <td v-if="totals.deliveries.total > 0">{{item.stats[0].metrics.deliveries.total}}</td>
                  <td v-if="totals.payments.complete > 0">{{item.stats[0].metrics.payments.complete}}</td>
                </tr>
                <tr class="text-subtitle-2">
                  <td>Total</td>
                  <td v-if="totals.deliverables.total > 0">{{totals.deliverables.total}}</td>
                  <td v-if="totals.submissions.total > 0">{{totals.submissions.total}}</td>
                  <td v-if="totals.deliveries.total > 0">{{totals.deliveries.total}}</td>
                  <td v-if="totals.payments.complete > 0" >{{totals.payments.complete}}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </async-state>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import ProfilePicker from "../components/ProfilePicker.vue"
import PeriodPicker from "../components/PeriodPicker.vue"
import AsyncState from "../components/AsyncState.vue"
import dayjs from "dayjs"
import {use} from "echarts/core"
import {CanvasRenderer} from "echarts/renderers"
import {LineChart} from "echarts/charts"
import {GridComponent, TooltipComponent, LegendComponent} from "echarts/components"
import VChart from "vue-echarts"
import axios from 'axios';

use([CanvasRenderer, LineChart, GridComponent, TooltipComponent, LegendComponent]);

export default {
  name: "Stats",
  components: { ProfilePicker, PeriodPicker, VChart, AsyncState },

  data() {
    return {
      period: "last7Days",
      computedPeriod: null,
      binSize: "day",
      
      items: null,

      promise: null,
      cancelToken: null
    };
  },

  computed: {
    profileId: {
      get() {
        return this.$root.profileId;
      },
      set(newVal) {
        this.$root.profileId = newVal;
      }
    },

    totals() {
      if (!this.items)
        return {};

      function addObject(src, dst) {
        for (let key in src) {
          if (typeof src[key] === "object") {
            dst[key] = dst[key] || {};
            addObject(src[key], dst[key]);
          } else {
            dst[key] = (dst[key] || 0) + src[key];
          }
        }
      }

      let totals = {};

      for (let item of this.items) {
        addObject(item.stats[0].metrics, totals);
      }
      return totals;
    },

    hasNonZeroStats() {
      return this.items && (
        this.totals.submissions.total > 0 ||
        this.totals.deliverables.total > 0 ||
        this.totals.deliveries.total > 0 ||
        this.totals.payments.complete > 0
      );
             
    },

    chartOption() {
      if (!this.items) return null;
      
      // See https://echarts.apache.org/en/option.html#color for default colour palette
      let series = [];
      if (this.totals.deliverables.total > 0) {
        series.push({
          name: "Content",
          data: this.items.map(item => item.stats[0].metrics.deliverables.total),
          type: 'line',
          color: '#ee6666'
        });
      }
      if (this.totals.submissions.total > 0) {
        series.push({
          name: "Guest Data",
          data: this.items.map(item => item.stats[0].metrics.submissions.total),
          type: 'line',
          color: '#5470c6'
        });
      }
      if (this.totals.deliveries.total > 0) {
        series.push({
          name: "Email Deliveries",
          data: this.items.map(item => item.stats[0].metrics.deliveries.total),
          type: 'line',
          color: '#fac858'
        });
      }
      if (this.totals.payments.complete > 0) {
        series.push({
          name: "Completed Payments",
          data: this.items.map(item => item.stats[0].metrics.payments.complete),
          type: 'line',
          color: '#91cc75'
        });
      }
      return {
        legend: { show: true },
        tooltip: { trigger: "axis" },
        xAxis: {
          type: 'category',
          data: this.items.map(item => this.formatDate(item.time)),
          boundaryGap: false
        },
        yAxis: { type: 'value' },
        series: series
      };
    }
  },

  methods: {
    load() {
      this.items = null;

      this.promise = (async () => {
        if (this.cancelToken) this.cancelToken.cancel();

        this.cancelToken = axios.CancelToken.source();

        let start_time = dayjs.utc(this.computedPeriod.start).startOf('day').toISOString();
        let end_time = dayjs.utc(this.computedPeriod.end).add(1, 'day').startOf('day').toISOString();

        let response = await this.$api.get("/stats/time-series", {
          params: {
            profile_id: this.profileId,
            bin_size: this.binSize,
            start_time,
            end_time
          },
          cancelToken: this.cancelToken.token
        });
        this.items = response.data.items;
      })();
    },

    formatDate(date) {
      return this.binSize === "day"
        ? dayjs(date).format("L")
        : dayjs(date).format("MMMM YYYY");
    },

    downloadFiguresCSV() {
      let rows = this.generateCSVRows();
      let name = this.generateCSVFileName();
      let csvData = this.convertToCSV(rows);
      
      this.downloadData(csvData, 'text/csv', name + '.csv');
    },

    generateCSVRows() {
      let headers = ['Date', 'Content', 'Guest Data', 'Email Deliveries', 'Completed Payments'];
      let rows = [headers];

      for (let item of this.items) {
        let date = this.formatCSVDate(item.time);
        let metrics = item.stats[0].metrics;

        let content = metrics.deliverables.total || 0;
        let guestData = metrics.submissions.total || 0;
        let emailDeliveries = metrics.deliveries.total || 0;
        let completedPayments = metrics.payments.complete || 0;

        rows.push([date, content, guestData, emailDeliveries, completedPayments]);
      }

      // Add total row
      rows.push(['Total', this.totals.deliverables.total, this.totals.submissions.total, this.totals.deliveries.total, this.totals.payments.complete]);

      return rows;
    },

    formatCSVDate(date) {
      return this.binSize === "day"
        ? dayjs(date).format('YYYY-MM-DD')
        : dayjs(date).format("MMMM YYYY");
    },

    generateCSVFileName() {
      let startTime = dayjs(this.computedPeriod.start);
      let endTime = dayjs(this.computedPeriod.end);
      return `${this.profileId} - ${startTime.format('L')} - ${endTime.format('L')} - Stats`;
    },

    convertToCSV(rows) {
      return rows.map(row => row.map(col => `"${col}"`).join(',')).join('\n');
    },

    downloadData(data, type, name) {
      let file = new Blob([data], { type });
      let a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = name;
      a.click();
    }
  },

  watch: {
    profileId() {
      this.load();
    },

    computedPeriod() {
      if (this.profileId) this.load();
    },

    binSize() {
      if (this.profileId) this.load();
    }
  }
}
</script>


<style scoped>
.chart {
  height: 400px;
}
</style>